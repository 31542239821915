import logo from './assets/logo-white.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-info">
          Site em construção.
        </p>
        <p className="App-info-s">
          Clique no link abaixo para conhecer o nosso trabalho.
        </p>
        <a
          className="App-link"
          href="https://maduoliveiraphoto.myportfolio.com/work"
          rel="alternate"
        >
          Madu Oliveira Portfólio
        </a>
      </header>
    </div>
  );
}

export default App;
